import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {
  page: string = "";

  projectLogos: any = [
    {"projectName": "Altares Habitat", "logo": "../../../../assets/projectsLogos/Altares.png"},
    {"projectName": "Altares Departamentos", "logo": "../../../../assets/projectsLogos/Altares.png"},
    {"projectName": "Bosco Hogares", "logo": "../../../../assets/projectsLogos/Bosco.png"},
    {"projectName": "Xalta", "logo": "../../../../assets/projectsLogos/Xalta.png"},
    {"projectName": "Xalter", "logo": "../../../../assets/projectsLogos/Xalter.png"},
    {"projectName": "Valle de las Palomas", "logo": "../../../../assets/isotipo.png"},
    {"projectName": "Nuevo Desarrollo", "logo": "../../../../assets/Altares.png"}
  ];
}
